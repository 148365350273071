<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3">
            <h5>Choose a filter to download a report</h5>
                <br>
				<CRow class="mb-2">
                    <CCol lg="6" class="mb-2">
						<label class="mb-1 label-font-10">Delivery Date From</label>
                        <Datepicker required  v-model="filter.delivery_date_from" input-class="form-control" placeholder=""/>
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
                        <label class="mb-1">Delivery Date To</label>
                        <Datepicker required  v-model="filter.delivery_date_to" input-class="form-control" placeholder="" />
                    </CCol>
                    <CCol lg="6" class="mb-0 label-font-10">
                        <label class="mb-1">Booking No.</label>
                        <CInput size="" v-model="filter.booking_no" />
                    </CCol>
                    <CCol lg="6" class="mb-0 label-font-10">
                        <label class="mb-1">Client Ref No.</label>
                        <CInput size="" v-model="filter.client_ref_no" />
                    </CCol>
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">Customer</label>
                        <v-select label="customer_name" :options="customer_list.data" :reduce="item => item.id" v-model="filter.customer_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
						<label class="mb-1">Booking Type</label>
						<v-select label="setting_name" :options="booking_list.data" :reduce="item => item.id" v-model="filter.booking_type_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
						<label class="mb-1">Commodity Type</label>
						<v-select label="setting_name" :options="commodity_list.data" :reduce="item => item.id" v-model="filter.commodity_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
						<label class="mb-1">Depot</label>
						<v-select label="setting_name" 
                             :options="depot_list.data" :reduce="item => item.id"
                             v-model="filter.depot_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="6" class="mb-2 label-font-10">
						<label class="mb-1">Origin</label>
						<v-select label="setting_name" :options="origin_list.data" :reduce="item => item.id" v-model="filter.origin_id" placeholder="-Select-"></v-select>
					</CCol>
                    
                    <!-- <CCol lg="12" class="mb-2">
						<label class="mb-1">Destination</label> -->
						<!-- <v-select label="setting_name" :options="destination_list.data" :reduce="item => item.id" v-model="filter.destination_id" placeholder="-Select-"></v-select> -->
                        <!-- <v-select label="name" :filterable="false" :options="options" @search="onSearch"></v-select> -->
					<!-- </CCol> -->
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">Trucker</label>
						<v-select label="vendor_name" :options="trucker_list.data" :reduce="item => item.id" v-model="filter.vendor_id" placeholder="-Select-"></v-select>
					</CCol>

                    <CCol lg="12" class="mb-1 label-font-10">
						<label class="mb-1">Plate Number</label>
						<CInput v-model="filter.plate_no"/>
					</CCol>
                    <!-- <CCol lg="12" class="mb-3">
                        <label class="mb-1">Status</label>
                        <v-select label="label" :options="status" :reduce="item => item.value" v-model="filter.booking_status" placeholder="-Select-"></v-select>
                    </CCol> -->
                   
                    <CCol lg="12" class="mb-2 label-font-10">
						<label class="mb-1">File Type</label>
                        <i class="fa fa-question-circle ml-1" title="xlsx (excel file) / csv (csv file)" ></i>
						<v-select :options="file_type_list" v-model="filter.file_type" placeholder="-Select-"></v-select>
					</CCol>
                  
                    <CCol lg="12" class="mb-2 label-font-10">
                        <h6 class="text-danger"> {{error_msg}}</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <!-- <CButton class="ml-1 mr-1 float-right" size="sm" color="info" @click="download"><i class="fa fa-file"></i> Generate Report</CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear">Clear</CButton> -->
            
            <CButton class="float-right" size="sm" color="info" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';  
import Datepicker from 'vuejs-datepicker';
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect, Datepicker },
    mounted() {
        // this.getCustomer();
        // this.getDepot();
        // this.getDestination();
        // this.getTrucker();
        // this.getOrigin();
        // this.getCommodity();
        // this.getBooking();
    },
    props: {
        showModalDownloadFilter: {required: true},
        booking_no: {required: true},
        asset_type: {required: true},
        client_ref_no: {required: true},
        booking_status: {required: true},
        filters : {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
                delivery_date_to: new Date(),
				delivery_date_from: moment(new Date()).subtract(1, 'month')._d,
                plate_no:'',
				customer_id: '',
                booking_status:'',
				depot_id:'',
				origin_id:'',
                destination_id:'',
                vendor_id:'',
                booking_id:'',
                boking_type_id:'',
                commodity_id:'',
                file_type:'xlsx',
                booking_no: "",
	            booking_type_id: "",
	            asset_type: "",
				client_ref_no: "",
                status: null
			},
            status:[
                {
                    label: 'All',
                    value: null
                },
                {
                    label: 'Under Review',
                    value: 'under review'
                },

                {
                    label: 'Approved',
                    value: 'approved'
                },

                {
                    label: 'Dispatched',
                    value: 'dispatched'
                },
                {
                    label: 'Delivered',
                    value: 'delivered'

                },
                {
                    label: 'Completed',
                    value: 'completed'
                },
                {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
                {
                    label: 'Declined',
                    value: 'declined'
                }
               
            ],
            destination_list :[],
            origin_list:[],
            depot_list:[],

            customer_list:[],
            trucker_list:[],
            booking_list:[],
            commodity_list:[],
            no_filter:false,
            currentPage:1,
            error_msg: '',
            processing:false,
            ready_download:false,
            link_download:'',
            file_type_list:[
                'xlsx',
                'csv'
            ],
            options: [],
            list_loaded: false,
            report_downloaded: false
        }
    },
    methods: {
        // onSearch(search, loading) {
        //     if(search.length) {
        //         loading(true);
        //         this.search(loading, search, this);
        //     }
        // },
        // search: _.debounce((loading, search, vm) => {
        //     fetch(
        //         `https://api.github.com/search/repositories?q=${escape(search)}`
        //         ).then(res => {
        //             res.json().then(json => (vm.options = json.items));
        //             loading(false);
        //         });
        //     }, 350)
        // },
        async download() {
            let msg =  'Please select a filter before downloading the report.';
            this.report_downloaded = false;
            if(this.dataValidate() && this.dateRangeValidate()){
                var delivery_date_from = this.filter.delivery_date_from;
                if(delivery_date_from != ""){
                    delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
                }
                else{
                    delivery_date_from = '-'
                }
                var delivery_date_to = this.filter.delivery_date_to;
                if(delivery_date_to != ""){	
                    delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
                    delivery_date_to = delivery_date_to + 86399; 
                }else{
                    delivery_date_to = '-'
                }
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                // this.$showLoading(true)
                await axios.post(`${config.api_path}/reports/booking`, {
                   
                       company_id:config.global_user_data.company_id,
                        customer_id:this.filter.customer_id ,
                        booking_type_id: this.filter.booking_type_id,
                        plate_no:this.filter.plate_no ,
                        depot_id:this.filter.depot_id ,
                        destination_id:this.filter.destination_id ,
                        booking_id:this.filter.booking_id ,
                        commodity_id:this.filter.commodity_id ,
                        asset_type:this.asset_type ,
                        booking_no:this.booking_no ,
                        booking_status:this.filter.booking_status ,
                        vendor_id:this.filter.vendor_id ,
                        origin_id:this.filter.origin_id ,
                        file_type:this.filter.file_type ,
                        client_ref_no:this.client_ref_no ,
                        delivery_date_from:delivery_date_from ,
                        delivery_date_to:delivery_date_to
                   
                })
                // await axios.get(config.api_path+'/reports/booking?'+
                // '&company_id='+config.global_user_data.company_id+
                // '&customer_id='+this.filter.customer_id+
                // '&plate_no='+this.filter.plate_no+
                // '&depot_id='+this.filter.depot_id+
                // '&destination_id='+this.filter.destination_id+
                // '&booking_id='+this.filter.booking_id+
                // '&commodity_id='+this.filter.commodity_id+
                // '&asset_type='+this.asset_type+
                // '&booking_no='+this.booking_no+
                // '&booking_status='+this.booking_status+
                // '&vendor_id='+this.filter.vendor_id+
                // '&origin_id='+this.filter.origin_id+
                // '&file_type='+this.filter.file_type+
                // '&client_ref_no='+this.client_ref_no+
                // '&delivery_date_from='+delivery_date_from+
                // '&delivery_date_to='+delivery_date_to
                // )
                .then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    this.error_msg = '';
                    // setTimeout(() => {
                    //     this.$showLoading(false)
                    //     window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file)
                      
                    // }, 3000);
                    // setTimeout(() => {
                    //     axios.get(`${config.api_path}/remove-file`,{
                    //         params : {
                    //             filename : response.data.file
                    //         }
                    //     })	
                    // }, 5000);
                })
                .catch(err => {
                    // this.$showLoading(false)
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                msg = 'Please input delivery date from and delivery date to.'
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
                this.error_msg = msg;
                this.dateFilterValidatePrompt();
            }
        },
        getCustomer() {
            axios.get(config.api_path + '/reference/customer-list', {
                params: {show_all: true}
            }).then(response => {
                this.customer_list = response.data;
            });
        },
        getDepot(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'depot'}
             }) .then(response => {
                this.depot_list = response.data;
            });
        },
        getDestination(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'destination'}
             }) .then(response => {
                this.destination_list = response.data;
            });
        },
        getOrigin(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'origin'}
             }) .then(response => {
                this.origin_list = response.data;
            });
        },
        getTrucker(){
            axios.get(config.api_path+'/reference/vendors-list')
            .then(response => {
                this.trucker_list = response.data;
            });
        },
        getCommodity(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'commodity_type'}
             }) .then(response => {
                this.commodity_list = response.data;
            });
        },
        getBooking(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'booking_type'}
             }) .then(response => {
                this.booking_list = response.data;
            });
        },
        clear(){
        
           this.delivery_date_to = new Date(),
			this.delivery_date_from=moment(new Date()).subtract(1, 'month')._d,
            this.filter.plate_no = '';
            this.filter.booking_no = '';
            this.filter.customer_id = '';
            this.filter.depot_id = '';
            this.filter.origin_id = '';
            this.filter.destination_id = '';
            this.filter.booking_status = null;
            this.filter.vendor_id = '';
            this.filter.booking_id = '';
            this.filter.booking_type_id = '';
            this.filter.commodity_id = '';
            this.filter.file_type='xlsx';
        },
        dataValidate(){
            if(
                this.filter.customer_id || 
                this.filter.booking_no || 
                this.filter.depot_id || 
                this.filter.destination_id ||
                this.filter.plate_no ||
                this.filter.origin_id ||
                this.filter.vendor_id ||
                this.filter.booking_status ||
                this.filter.booking_id ||
                this.filter.booking_type_id ||
                this.filter.commodity_id ||
                (this.filter.delivery_date_from && this.filter.delivery_date_to && this.dateRangeValidate)
            ){
                return true;
            }
            else{
                return false;
            }
        },
        dateRangeValidate(){
            let from = this.filter.delivery_date_from;
            let to = this.filter.delivery_date_to;
            if(!from || !to){
                return false;
            }else{
                if(from && to){
                    const start = new Date(from).setHours(0, 0, 0);
                    const end = new Date(to).setHours(23, 59, 59);
                    // Calculate the difference in milliseconds
                    const differenceInMilliseconds = end - start;
                    // Calculate the difference in week
                    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                    // Check if the difference is not greater than 1 week
                    return differenceInDays <= 3;
                }
                else{
                    return false;
                }
            }
        },
        dateFilterValidatePrompt(){
            let from = this.filter.delivery_date_from;
            let to = this.filter.delivery_date_to;
            if(from && to){
                const start = new Date(from).setHours(0, 0, 0);
                const end = new Date(to).setHours(23, 59, 59);
                // Calculate the difference in milliseconds
                const differenceInMilliseconds = end - start;
                // Calculate the difference in week
                const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30);
                // Check if the difference is not greater than 1 week
                if(!(differenceInDays <= 3)){
                    this.error_msg = 'Filter date should not exceed 3 months.';
                }
            }
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: async function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            if(this.showModalDownloadFilter && !this.list_loaded) {
                await this.getCustomer();
                await this.getDepot();
                await this.getDestination();
                await this.getTrucker();
                await this.getOrigin();
                await this.getCommodity();
                await this.getBooking();

                this.list_loaded = true;
            }

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        },
        booking_status :function(val){
            this.filters.booking_list= val
        },
       
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}


</style>
